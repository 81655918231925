.page-loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 7777;
}

.page-loader .progressbar {
    display: block !important;
}