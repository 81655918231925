@media (max-width: 768px) {
    .header .registration-button, .header .logout-button {
        margin-top: 2em;
    }
}

.header {
    height: 78px;
    width: 100%;
    z-index: 0;
}

.audi-logo {
    width: 93px;
    height: 32px;
    margin-left: 16px;
}

@media (max-width: 768px) {
    .audi-logo {
        margin-left: 0;
    }
}

.icon-button {
    height: 46px;
}

.header.mobile .container {
    height: 100%;
    z-index: 10;
}

.user-burger {
    margin-right: calc(1em + 10px);
    margin-left: 5px;
    z-index: 1;
}

.user-burger.open {
    z-index: 3;
}

.user-burger .profile-icon {
    width: 30px;
    height: 30px;
    position: relative;
    z-index: 4;
}

.user-burger .profile-icon .head {
    position: absolute;
    left: 27.5%;
    top: 0;
    width: 45%;
    height: 45%;
    background-color: black;
    border-radius: 50%;
}

.user-burger .profile-icon .body {
    position: absolute;
    top: 50%;
    left: 2%;
    width: 96%;
    height: 50%;
    background-color: black;
    border-radius: 30px 30px 10px 10px;
}

.user-burger .dropdown {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 0;
    overflow: hidden;
    background-color: white;
    transition: height 0.3s ease-in-out 0.1s,
                padding-top 0.3s linear 0.1s;
    padding: 0 1em 0;
}

.user-burger .dropdown.open {
    height: 100%;
    padding: 78px 1em 1em;
    transition: height 0.3s ease-in-out,
                padding-top 0.3s ease-in-out;
}

.user-burger .dropdown .content {
    opacity: 0;
    transition: opacity 0.1s linear;
}

.user-burger .dropdown.open .content {
    opacity: 1;
    transition: opacity 0.1s linear 0.3s;
}