.game-header {
    background-color: #F2F2F2;
    width: 100%;
    padding: 0.5em 8vw;
    box-sizing: border-box;
}

@media (max-width: 768px) {
    .game-header {
        text-align: center;
    }
}