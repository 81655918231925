@media (max-width: 768px) {
    .audi-logo-inverted {
        margin-top: 1em;
    }
}

.footer {
    width: 100%;
    background-color: black;
}

.footer .audi-logo-inverted {
    width: 93px;
    height: 32px;
}

.footer .legal-rights {
    color: white !important;
}

.footer .footer-layout {
    height: 100%;
}

.footer .contact-link a {
    border: 0;
    color: #808080 !important;
}

.footer .contact-link a:hover {
    color: #808080 !important;
}

.footer .social {
    height: 1.8em;
    width: 1.8em;
    margin: 0.5em;
}