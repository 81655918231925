.throwback {
    width: var(--width);
    --height: 30vw;
    --element-width: 30vw;
    --element-height: 20vw;
}

.throwback section {
    width: 100%;
    background-size: cover;
    background-position: center;
    position: relative;
    overflow: hidden;
}

.throwback .top {
    width: 100vw;
    height: var(--height);
}

.throwback section .top .backdrop {
    position: absolute;
    left: 0;
    right: 0;
    width: 100vw;
    height: 110%;
    background-size: cover;
    background-position: center;
    filter: brightness(50%);
    transition-duration: 0.5s;
}

.throwback section .content .image, .throwback section .content iframe {
    width: var(--element-width);
    height: var(--element-height);
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center;
    transition-duration: 0.5s;
}

.throwback section .content iframe {
    height: calc(var(--element-width) * (18 / 16));
    width: 90vw;
}

.throwback .description img {
    float: left;
    height: auto;
    margin-right: 2.5em;
    max-width: 60%;
}

.throwback .description {
    background-color: white;
}

.throwback .description {
    height: auto;
    padding: 2em 0;
}

.throwback .top .backdrop {
    position: absolute;
    left: 0;
    right: 0;
    width: 100vw;
    height: 140%;
    background-size: inherit;
    background-position: center;
    transition-duration: 0.5s;
    filter: brightness(50%);
}

.throwback section .content {
    height: 100%;
}

@media (max-width: 1200px) {
    .throwback {
        --width: 100vw;
    }

    .throwback .description .content {
        width: 60vw;
    }

    .throwback .description img {
        float: none !important;
        max-width: 100%;
    }
}

@media (max-width: 1000px) {
    .throwback {
        --width: 100vw;
    }

    .throwback .description .content {
        width: 80vw;
    }
}

@media (max-width: 768px) {

    .throwback button {
        width: auto;
    }

    .throwback .description .content {
        width: 90vw;
    }

    .throwback .top {
        padding-top: 0 !important;
    }

    .throwback section .image {
        margin-top: 1em;
    }

    .throwback section .image.hide {
        display: none;
    }
}