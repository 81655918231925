.contact {
    --width: 60vw;
    width: var(--width);
}

@media (max-width: 1200px) {
    .contact {
        --width: 80vw;
    }
}

@media (max-width: 1000px) {
    .contact {
        --width: 95vw;
    }
}