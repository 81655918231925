.timer .section {
    width: 5.5vw;
    height: 4px;
    position: relative;
    margin: 0.5vw;
}

.timer .section .background, .timer .section .bar {
    border-radius: 2px;
    height: 4px;
    position: absolute;
    top: 0;
    left: 0;
}

.timer .section .background {
    background-color: #CCCCCC;
    width: 5.5vw;
}

.timer .section .bar {
    background-color: #000000;
}

.timer .danger {
    color: #F50537;
}

@media (max-width: 768px) {
    .timer {
        margin-top: 0.5em;
    }

    .timer .section, .timer .section .bar, .timer .section .background {
        width: 10vw;
    }
}