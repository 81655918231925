.modal-container .modal, .modal-container .backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 555;
}

.modal-container .backdrop {
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
}

@media (max-width: 768px) {
    .modal-container .modal .container {
        margin: 0 !important;
        max-width: 100vw !important;
        width: 100vw;
        max-height: 100vh !important;
        height: 100vh;
    }

    .modal-container .modal button {
        width: auto;
    }

}

.modal-container .modal .container {
    max-width: 55vw;
    max-height: calc(100vh - 12em);
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 5em;
    background-color: white;
    padding: 1.5em;
    position: relative;
}

.modal-container .modal .container .cross {
    user-select: none;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0.2em;
    transform: rotate(45deg);
    font-size: 2rem;
}