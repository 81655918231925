.Intern {
    --width: 60vw;
    width: var(--width);
}

@media (max-width: 1200px) {
    .Intern {
        --width: 80vw;
    }
}

@media (max-width: 1000px) {
    .Intern {
        --width: 95vw;
    }
}

@media (max-width: 768px) {

    .Intern button {
        width: auto;
    }
}