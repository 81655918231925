.game {
    width: 100%;
}

.game .image-container .image {
    width: 100%;
    align-self: start;
}

@media (min-width: 768px) {
    .game .image-container.sticky {
        position: sticky;
        top: 1em;
    }
}

.game .content {
    width: 40vw;
    padding: 1.5vw;
}

.game .small {
    font-size: 0.825rem;
    line-height: 1.3rem;
}

.textinput-container {
    width: 100%;
}

.textinput-container input {
    text-align: right;
}

.navigation-buttons {
    width: 80vw;
    margin: 1em auto 1em;
}

@media (max-width: 768px) {
    .game .content {
        width: 90vw;
    }
    .navigation-buttons {
        width: 90vw;
    }
    .navigation-buttons button {
        width: 40vw;
    }
}

.table-input {
    width: 100%;
    display: flex;
}

.table-input-cell {
    float: left;
    border: 1px solid black;
}

.table-input-cell input {
    text-align: center;
}

.table-input-cell .title {
    border-bottom: 1px solid black;
    text-align: center;
}

.finish-button {
    background-color: #BB0A30 !important;
    border: 0 !important;
}

.header-container {
    width: 100%;
}

table {
    border-collapse: collapse;
    margin-bottom: 1em;
}

.index,
.answer {
    border: 1px solid black;
}

.answer {
    text-align: left;
}

.answer.given {
    font-weight: bold;
}

.index {
    height: 50px;
    width: 10%;
    background: #cccccc;
}

.table-title {
    border: 1px solid black;
    background: #cccccc;
}

.select {
    height: 10px;
}

.pregiven {
    padding-left: 25px;
}

.save-button-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: end;
    margin-top: 10px;
}
.save-button-container .save-button{
    margin-bottom: 5px;
}