.manufactoring {
    --height: 30vw;
    --element-width: 30vw;
    --element-height: 20vw;
    --width: 100%;
    width: var(--width);
}

.manufactoring section {
    margin: 0 auto;
    width: 60vw;
    /*height: var(--height);*/
    background-size: cover;
    background-position: center;
    position: relative;
    overflow: hidden;
    text-align: justify;
}

.manufactoring .middle {
    height: calc(var(--height) * 1.4);
}

.manufactoring .logo {
    height: 5em;
    margin-top: -2em;
    margin-left: 0.5em;
    width: auto;
}

.manufactoring .gallery-section {
    height: 600px;
}

.manufactoring section .gallery {
    position: absolute;
    left: 0;
    right: 0;
    width: 100vw;
    height: 110%;
    background-size: cover;
    background-position: 50% 25%;
    transition-duration: 0.5s;
}

.manufactoring .bottom .gallery {
    filter: brightness(100%);
}

.manufactoring section .content {
    height: 100%;
}

.manufactoring section img {
    width: var(--element-width);
}

.manufactoring section .content div {
    width: var(--element-width);
}

.manufactoring section .content .image, .manufactoring section .content iframe {
    width: var(--element-width);
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center;
    transition-duration: 0.5s;
}

.manufactoring section .content iframe {
    height: 40vw;
    width: 50vw;
}

.manufactoring section .content .image {
    height: var(--element-height);
}

.manufactoring section .content iframe {
    height: calc(var(--element-width) / 16 * 9 * 1.6);
}

.manufactoring .description {
    background-color: #F2F2F2;
}

.manufactoring .description {
    height: auto;
    padding: 2em 0;
}

.manufactoring .description .content {
    width: calc(2 * var(--element-width));
}

.manufactoring .description img {
    float: left;
    height: auto;
    margin-right: 2.5em;
}

.manufactoring .faq {
    margin-left: 10vw;
    max-width: 80vw;
    padding-top: 4em;
    height: auto;
    padding-bottom: 4em;
}

.manufactoring .rounds .round {
    width: min(30vw, 400px);
    margin: 1vw;
    position: relative;
    height: calc(min(30vw, 400px) / 16 * 11);
}

.manufactoring .rounds .round .container {
    height: 100%;
}

.manufactoring .rounds .round-backdrop {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: -1;
    filter: brightness(50%);
}

.manufactoring .white {
    color: white;
    text-align: left;
}

.manufactoring .black {
    color: rgb(51, 51, 51);
}

.manufactoring .holder {
    margin-left: -100em;
}

.manufactoring .title {
    text-align: center;
    margin: 1em 0;
}

@media (max-width: 1200px) {
    .manufactoring {
        --width: 80vw;
    }

    .manufactoring section {
        width: 80vw;
    }
}

@media (max-width: 1000px) {
    .manufactoring {
        --width: 95vw;
    }

    .manufactoring section {
        width: 90vw;
    }
}

@media (max-width: 768px) {

    .manufactoring button {
        width: auto;
    }

    .manufactoring section {
        width: 90vw;
    }
}

.middle iframe {
    margin: 5em 0;
    height: 28vw;
    width: 50vw;
}

.title {
    text-align: center;
}

.description img {
    float: left;
    margin-right: 2.5em;
    height: auto;
}

.description {
    background-color: #F2F2F2;
    height: auto;
    padding: 2em 0;
    width: 100%;
}

.description .content {
    width: calc(2 * var(--element-width));
}

.description .content p {
    text-align: justify;
}



.description .content .question {
    font-style: italic;
    margin: 2em 0 .5em 0;
}

@media (max-width: 1200px) {
    .manufactoring {
        --height: 40vw;
        --element-width: 40vw;
        --element-height: 30vw;
    }
}

@media (max-width: 1000px) {
    .manufactoring {
        --height: 50vw;
        --element-width: 43vw;
        --element-height: 40vw;
    }
}

@media (max-width: 800px) {
    .manufactoring {
        --height: 55vw;
        --element-width: 47vw;
        --element-height: 50vw;
    }
}

@media (max-width: 768px) {
    .manufactoring {
        --height: auto;
        --element-width: 100%;
        --element-height: 55vw;
    }

    .manufactoring .gallery-section {
        height: 200px;
    }

    .manufactoring .faq {
        width: 100vw !important;
        padding: 5vw 5vw !important;
        text-align: center;
        height: auto !important;
        box-sizing: border-box;
        margin: 0 !important;
        max-width: 100vw !important;
    }

    .manufactoring section .image {
        margin-top: 1em;
    }

    .manufactoring section .image.hide {
        display: none;
    }

    .manufactoring .logo {
        margin-top: 1em !important;
    }

    .manufactoring .description img {
        float: none !important;
    }

    .manufactoring section .content iframe {
        width: 90vw;
        height: calc(90vw / 16 * 9) !important;
        margin: 2.5em 0;
    }

    .manufactoring .rounds .round {
        width: 90vw !important;
        height: calc(90vw / 16 * 9) !important;
        margin: 1vw 0 !important;
    }

    .manufactoring .rounds .round-backdrop {
        height: calc(90vw / 16 * 9) !important;
    }

    .manufactoring .top {
        padding-top: 0 !important;
    }
}