.navigation-burger {
    width: 40px;
    height: 40px;
    position: relative;
    margin-left: 1em;
    z-index: 2;
}

.navigation-burger.open {
    z-index: 3;
}

.navigation-burger .dropdown {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 0;
    overflow: hidden;
    background-color: white;
    transition: height 0.3s ease-in-out 0.1s,
                padding-top 0.3s linear 0.1s;
    z-index: -1;
}

.navigation-burger .dropdown ul {
    list-style-type: none;
    padding: 0;
    opacity: 0;
    transition: opacity 0.1s linear;
    border-bottom: 1px solid black;
}

.navigation-burger .dropdown .menu-item {
    width: 100%;
    padding: 1em;
    box-sizing: border-box;
    border-top: 1px solid black;
}

.navigation-burger .dropdown .menu-item a {
    text-decoration: none;
}

.navigation-burger .dropdown.open {
    height: 100%;
    padding-top: 78px;
    transition: height 0.3s ease-in-out,
                padding-top 0.3s ease-in-out;
}

.navigation-burger .dropdown.open ul {
    width: 100%;
    opacity: 1;
    transition: opacity 0.1s linear 0.3s;
}

.navigation-burger .line {
    position: absolute;
    left: 5px;
    width: 80%;
    height: 2px;
    background-color: black;
    display: block;
}

.navigation-burger .line.top {
    top: 30%;
}

.navigation-burger .line.middle {
    top: calc(50% - 1px);
}

.navigation-burger .line.bottom {
    bottom: 30%;
}

.navigation-burger.open .line.top {
    animation: move-top 0.5s linear 0s 1 normal forwards;
}

.navigation-burger.closed .line.top {
    animation: move-top 0.5s linear 0s 1 reverse forwards;
}

.navigation-burger.open .line.middle {
    animation: move-middle 0.5s linear 0s 1 normal forwards;
}

.navigation-burger.closed .line.middle {
    animation: move-middle 0.5s linear 0s 1 reverse forwards;
}

.navigation-burger.open .line.bottom {
    animation: move-bottom 0.5s linear 0s 1 normal forwards;
}

.navigation-burger.closed .line.bottom {
    animation: move-bottom 0.5s linear 0s 1 reverse forwards;
}

a {
    text-decoration: none;
}

.menu-link, .menu-item .menu-link {
    text-decoration: none;
}

.navbar {
    --size: 0 !important;
}

@keyframes move-top {
    0% {
        top: 30%;
    }

    50% {
        top: calc(50% - 1px);
        transform: rotate(0deg);
    }

    100% {
        top: calc(50% - 1px);
        transform: rotate(45deg);
    }
}

@keyframes move-bottom {
    0% {
        bottom: 30%;
    }

    50% {
        bottom: calc(50% - 1px);
        transform: rotate(0deg);
    }

    100% {
        bottom: calc(50% - 1px);
        transform: rotate(-45deg);
    }
}

@keyframes move-middle {
    0% {
        top: calc(50% - 1px);
    }

    50% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(45deg);
    }
}