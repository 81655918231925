.alert {
    padding: 1em;
    position: fixed;
    top: 0.5em;
    right: 0.5em;
    transition-duration: 0.5s;
    z-index: 9999;
    opacity: 1;
    min-width: 200px;
    max-width: 320px;
    border-radius: 5px;
}

.alert.inactive {
    transform: translate(100%, 0);
    opacity: 0;
}

.alert span {
    color: white !important;
}

.alert.success {
    background-color: #009900;
}

.alert.danger {
    background-color: #BB0A30;
}

.alert.info {
    background-color: #FFAA00;
}