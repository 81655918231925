.faq {
    text-align: left !important;
}

.faq hr {
    margin-top: 0;
    margin-bottom: 0;
}

.faq .open-arrow {
    width: 30px;
    height: 30px;
    margin-right: 0.5em;
    transition-duration: 0.1s;
    transition-delay: 0.1s;
}

.faq .rotated {
    transform: rotate(180deg);
    transition-duration: 0.1s;
    transition-delay: 0s;
}

.faq .question {
    cursor: pointer;
}

.faq .question .answer {
    transition: opacity 0.1s linear 0.1s,
                margin 0.1s;
    pointer-events: none;
}

.faq .question .answer.closed {
    opacity: 0;
    transition: opacity 0.1s,
                margin 0.1s linear 0.1s;
}