.cookie .audi-logo {
    height: 1.5em;
}

.cookie button {
    width: auto;
}

.cookie {
    max-width: 40em;
    text-align: justify;
    box-sizing: border-box;
}

.cookie .no-justify {
    text-align: left;
}

.cookie .extra {
    height: auto;
    overflow: hidden;
}

.cookie .extra.hidden {
    height: 0;
}

.cookie a {
    text-decoration: none;
}

