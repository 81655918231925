.countdown {
    height: 70vw;
    background-size: cover;
    background-image: url("./dashboard-background_v3.jpg");
    box-sizing: border-box;
}

.countdown .centered {
    max-width: 400px;
    text-align: center;
}

.countdown .loader {
    display: block;
}

.countdown .title {
    color: white !important;
}

.countdown .timer {
    height: 58px;
    cursor: default !important;
    color: white;
    border-color: white;
}

.survey {
    width: 100%;
    box-sizing: border-box;
}

.survey .part {
    max-width: 61vw;
}

.upload {
    width: 100%;
    box-sizing: border-box;
}

.upload .part {
    max-width: 40vw;
}

.upload .mobility {
    display: inline-block;
    margin: 0 1em;
}

@media (max-width: 768px) {
    .upload .part {
        max-width: 95vw;
    }
}