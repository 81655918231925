.registration {
    background-color: #F2F2F2;
}

.registration .info {
    font-family: serif;
    padding: 0.1em;
    display: inline-block;
    width: 1em;
    font-weight: bold;
    height: 1em;
    text-align: center;
    line-height: 1em;
    background-color: black;
    color: white;
    border-radius: 50%;
    user-select: none;
}

.intern-form {
    overflow: hidden;
}

.intern-form p {
    color: #F50537;
}

.intern-form.hidden {
    height: 0;
}

.registration .registration-title {
    margin-top: 1em;
}

.lb {
    margin-left: 1.8em;
    margin-bottom: -.5em;
}

.intern-interests {
    margin-left: .2em;
}

.intern-interest {
    margin: 1em 0 1em 1.4em;
}

.intern-interests span {
    display: none;
}

.registration-letter span {
    display: none;
}

.intern-other-lang span {
    display: none;
}

.error {
    color: #eb0d3f !important;
    margin: 1em 0 !important;
    text-align: center;
}